import { Link, useLocation } from 'react-router-dom'

import dashboardImage from '../assets/svg/dashboard.svg'
import dataCatalogImage from '../assets/svg/data-catalogs.svg'
import manageAccessImage from '../assets/svg/manage-access.svg'
import reportAnalyticsImage from '../assets/svg/report-analytics.svg'
import inferenceExplorerImage from '../assets/svg/inference-explorer.svg'

import LinkedinSVG from '../assets/svg/linkedin.svg'

export default function RightHoldersFooter() {
  const pathname = useLocation().pathname

  if (pathname !== '/datacatalog/explorecatalog') {
    return (
      <>
        <footer className="pt-16 px-14 pb-8 bg-[#FCFDFD]">
          <div className="flex flex-col gap-y-8 xl:flex-row xl:justify-between">
            <div className="flex flex-row justify-between xl:justify-normal xl:flex-col gap-y-3 xl:w-[435px]">
              <div>
                <img src={'/musical-ai.svg'} width="188" height="28" />
                <p className="font-medium text-[#304050]">
                  Rights Management for AI
                </p>
              </div>

              <Link
                href="#linkedin"
                className="w-fit h-fit p-2 bg-[#D4D4D866] rounded-xl"
              >
                <img src={LinkedinSVG} width={24} height={24} alt="linkedin" />
              </Link>
            </div>

            <div className="grid sm:grid-cols-3 gap-16">
              <ol className="*:text-[#304050] *:mb-3">
                <li>
                  <span className="text-[#3F3F46] font-bold">Support</span>
                </li>
                <li>Technical Support</li>
                <li>888-888-0987</li>
                <li>Account & Sales Support</li>
                <li>888-323-2523</li>
              </ol>
              <ol className="*:text-[#304050] *:mb-3">
                <li>
                  <span className="text-[#3F3F46] font-bold">
                    Term and Conditions
                  </span>
                </li>
                <li>EULA</li>
                <li>Copyright and licensing</li>
                <li><a href="/terms" >Terms of Use</a></li>
                <li>Data and Privacy</li>
              </ol>
              <ol className="*:text-[#304050] *:mb-3">
                <li>Dashboard</li>
                <li>Account</li>
                <li>Corporate Website</li>
              </ol>
            </div>
          </div>

          <p className="mt-16 text-center text-[#71717A] text-sm">
            © SOMMS AI Inc. All rights reserved.
          </p>
        </footer>
        {/* Mobile Navbar */}
        <nav className="bg-[#FCFDFD] sticky bottom-0 left-0 lg:hidden border-t border-t-[#E5E5E5] py-3 flex gap-x-6 justify-center">
          <MobileMenuItem
            href={'/'}
            pathname={pathname}
            title="Dashboard"
            icon={
              <img src={dashboardImage} className="w-6 h-6 sm:w-4 sm:h-4" />
            }
          />
          <MobileMenuItem
            href={'/datacatalog'}
            pathname={pathname}
            title="Catalog"
            icon={
              <img src={dataCatalogImage} className="w-6 h-6 sm:w-4 sm:h-4" />
            }
          />
          <MobileMenuItem
            href={'/manageaccess'}
            pathname={pathname}
            title="Manage Access"
            icon={
              <img src={manageAccessImage} className="w-6 h-6 sm:w-4 sm:h-4" />
            }
          />
          <MobileMenuItem
            href={'/reportanalytics'}
            pathname={pathname}
            title="Reports & Analytics"
            icon={
              <img src={reportAnalyticsImage} className="w-6 h-6 sm:w-4 sm:h-4" />
            }
          />
          <MobileMenuItem
            href={'/inferenceexplorer'}
            pathname={pathname}
            title="Generated Tracks"
            icon={
              <img
                src={inferenceExplorerImage}
                className="w-6 h-6 sm:h-4 sm:w-4"
              />
            }
          />
        </nav>
      </>
    )
  }

  return null
}

function MobileMenuItem({ icon, title, href, pathname }) {
  return (
    <Link
      to={href}
      className={`py-2 px-3 rounded transition-all duration-150 flex flex-col items-center gap-y-[6px] ${pathname === href ? "bg-[#E7F1FF]" : "hover:bg-white"}`}
    >
      {icon}
      <span className="max-sm:hidden text-[#212529] text-sm font-bold max-w-[66px] text-center">
        {title}
      </span>
    </Link>
  )
}
