import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import React from "react"
import Dashboard from "./pages/dashboard";
import DataCatalogs from "./pages/datacatalogs";
import ReportAnalytics from "./pages/reportanalytics";
import Playbacktest2 from './pages/playbacktest'
import ManageAccess from "./pages/managelicenses";
import InferenceExplorer from "./pages/generatedtracks";
import ExploreDataCatalogs from "./pages/exporecatalog";
import TrainingData from "./pages/AiCompany/TrainingData";
import ModelsWorkflow from "./pages/AiCompany/ModelsWorkflow";
import Settings from "./pages/AiCompany/Settings";
import NotFound from "./NotFound";
import CatalogInfo from "./pages/AiCompany/catalogInfo";
import FullMetadataCatalog from "./pages/AiCompany/fullMetadataCatalog";
import MetadataOverview from "./pages/AiCompany/metadataOverview";
import TrainingSetDetails from "./pages/AiCompany/trainingSetDetails";
import Termspage from "./pages/Termspage";

import { DataContext } from "./contexts/Site"
import Header from "./components/header";

// Navbar meme
import dashboardImage from './assets/svg/dashboard.svg'
import dataCatalogImage from './assets/svg/data-catalogs.svg'
import manageAccessImage from './assets/svg/manage-access.svg'
import reportAnalyticsImage from './assets/svg/report-analytics.svg'
import inferenceExplorerImage from './assets/svg/inference-explorer.svg'
import trainingDataImage from './assets/svg/training-data.svg'
import modelWorkflowImage from './assets/svg/models-workflow.svg'
import settingImage from './assets/svg/settings.svg'

// import { DataContext } from "./contexts/Site"
import { Link, NavLink } from 'react-router-dom'
import AccountSettings from './pages/acccountsettings'
import RightHoldersFooter from './components/RightHoldersFooter'

const NavItem = ({ to, end, children }) => (
  <NavLink
    to={to}
    end={end}
    className={({ isActive }) => (isActive ? 'active' : '')}
  >
    {({ isActive }) => <li className={isActive ? 'active' : ''}>{children}</li>}
  </NavLink>
)

export default function Routing(){
    const { companyType } = React.useContext(DataContext)
    
    return (
        <>
            {
                companyType !== null ? (
                    <>
                        <Router>
                            <aside className="navbar max-lg:hidden bg-[#FCFDFD] h-screen sticky top-0 left-0">

                                <LogoLink />
                                {
                                companyType !== null ? companyType === "RightHolder" ? (
                                    <ul className="*:font-normal">
                                
                                    <NavItem to="/"> {' '} <img src={dashboardImage} width="16" height="16"/> {' '} Dashboard </NavItem>
                                    <NavItem to="/datacatalog"> {' '} <img src={dataCatalogImage} width="16" height="16"/> {' '} Catalog </NavItem>
                                    <NavItem to="/managelicenses" > {' '} <img src={manageAccessImage} width="16" height="16" />  {' '} Manage Licenses</NavItem>
                                    <NavItem to="/reportanalytics"> {' '} <img src={reportAnalyticsImage} width="16" height="16" /> {' '} Reports & Analytics </NavItem>
                                    <NavItem to="/generatedtracks"> {' '}<img src={inferenceExplorerImage} width="16" height="16" /> {' '} Generated Tracks </NavItem>
                                    
                                    </ul>
                                ): (
                                    <ul className="*:font-normal">
                                
                                    {/* <li className={basePath === '' ? 'active' : ''}><a href="/"> <img src={dashboardImage} width="16" height="16"/> Dashboard </a></li> */}
                                    <NavItem to="/"> {' '} <img src={trainingDataImage} width="16" height="16"/> {' '} Training Data </NavItem>
                                    <NavItem to="/modelsworkflow" > {' '} <img src={modelWorkflowImage} width="16" height="16" /> {' '} Models & Workflow </NavItem>
                                    <NavItem to="/reportanalytics"> {' '} <img src={reportAnalyticsImage} width="16" height="16" /> {' '} Reports & Analytics </NavItem>
                                    <NavItem to="/settings"> {' '} <img src={settingImage} width="16" height="16" /> {' '} Settings </NavItem>
                                    <NavItem to="/generatedtracks"> {' '} <img src={inferenceExplorerImage} width="16" height="16" /> {' '} Generated Tracks </NavItem>
                                    
                                    </ul>
                                )
                                    : (
                                    <>
                                    </>
                                    )
                                }
                                
                                </aside>
                                <main>
                                    <Header/>
                                    <Routes>
                                        {/* <Route exact path="/" element={<Dashboard />} /> */}
                                        {
                                            companyType === "RightHolder" ? (
                                                <>
                                                    <Route exact path="/" element={<Dashboard/>}/>
                                                    <Route exact path="/datacatalog" element={<DataCatalogs />} />
                                                    <Route exact path="/managelicenses" element={<ManageAccess />} />
                                                    <Route exact path="/datacatalog/explorecatalog" element={<ExploreDataCatalogs />}/>

                                                    <Route
                                                        exact
                                                        path="/account-settings"
                                                        element={<AccountSettings />}
                                                    />
                                                </>
                                                
                                            ):
                                            (
                                                <>
                                                    <Route exact path="/" element={<TrainingData />} />
                                                    <Route exact path="/modelsworkflow" element={<ModelsWorkflow />} />
                                                    <Route exact path="/settings" element={<Settings />} />
                                                    <Route exact path="/metadataOverview/:id" element={<MetadataOverview />} />
                                                    <Route exact path="/fullMetadataCatalog/:id?" element={<FullMetadataCatalog />} />
                                                    <Route exact path="/catalogInfo/:id" element={<CatalogInfo />} />
                                                    <Route exact path="/trainingsetdetails/:id" element={<TrainingSetDetails />}/>
                                                </>
                                                
                                            )

                                        }
                                        <Route exact path="/reportanalytics" element={<ReportAnalytics />} />
                                        <Route exact path="/generatedtracks" element={<InferenceExplorer />} />
                                        <Route exact path="/terms" element={<Termspage />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                    {companyType === 'RightHolder' ? <RightHoldersFooter /> : null}
                                </main>
                            
                        </Router>
                    
                    </>
                    
                ):
                (
                    <>
                    </>
                )
              
            }
        </>
  )
}

export function LogoLink({ className, imgClassName }) {
  return (
    <Link to="https://www.app.wearemusical.ai/" className={className || ''}>
      <img
        src={'/musical-ai.svg'}
        width="216"
        height="56"
        className={'w-[216px] h-14 ' + imgClassName}
      />
    </Link>
  )
}
