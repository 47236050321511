import React from 'react'
import { Tabs, Tab } from '@nextui-org/tabs'
import { useMediaQuery } from '@uidotdev/usehooks'

import ContactSupport from '../components/ContactSupport'
import UserDetails from '../components/account-settings/userDetails'
import Terms from '../components/Terms'
import Eula from '../components/account-settings/eula'
import { DataContext } from '../contexts/Site'

function AccountSettings() {
  const isMobile = useMediaQuery('only screen and (max-width : 768px)')
  const { rightSummary, companyName, borrowingCompanies } =
  React.useContext(DataContext)

  return (
    <main className="max-w-[1150px] py-9 px-6 sm:px-9">
      <section>
        <div className="flex justify-between items-center">
          <p className="text-2xl font-medium text-[#304050]">
            Account Settings
          </p>
          <ContactSupport />
        </div>

        <h1 className="mt-6 font-medium text-[40px] leading-12 text-[#000000DE]">
          {companyName} 
        </h1>
      </section>

      <Tabs aria-label="Account settings" className="mt-9">
        <Tab
          key="user-detail"
          title={isMobile ? 'User Settings' : 'User Details and Password'}
        >
          <UserDetails />
        </Tab>
        <Tab
          key="terms-of-service"
          title={isMobile ? 'Terms' : 'Terms of Service'}
        >
          <Terms />
        </Tab>
        <Tab key="eula" title="EULA">
          <Eula />
        </Tab>
      </Tabs>
    </main>
  )
}

export default AccountSettings
