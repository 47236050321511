import React from 'react'
import Modal from './modals/Modal'
import Terms from './Terms';
import '../style/notification.css'


export default function TermsModal(props) {
    const {isTermsOpen, setTermsOpen} = props
    
    const closeModal = () => {
        setTermsOpen(false);
    };

    return (
        <Modal isOpen={isTermsOpen} onClose={closeModal}>
            <div className="p-6 bg-white rounded-lg w-[600px] max-w-[90%] shadow-[0px_16px_48px_0px_#0000002D;] border border-black/20">
                <div className="flex items-stretch mb-9 align-center justify-center">
                   <div><b>Accept Terms</b></div>
                {/* <button className="close-button" onClick={closeModal}><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.4922 7.02609L18.0822 5.61609L12.4922 11.2061L6.90219 5.61609L5.49219 7.02609L11.0822 12.6161L5.49219 18.2061L6.90219 19.6161L12.4922 14.0261L18.0822 19.6161L19.4922 18.2061L13.9022 12.6161L19.4922 7.02609Z"
                        fill="black" />
                    </svg>
                </button> */}
                </div>

                    <div className="text-sm mb-9 h-[470px]">
                    <div className="overflow-y-auto h-[450px]">
                    <Terms/>

</div>
        <div className="flex items-stretch align-center justify-center mt-5" > 
            <button onClick={(event) => closeModal(event, 'accept')} className="bg-[#EAECEF] rounded py-2 px-4">Accept</button>
        </div>
                </div>
            </div>
        </Modal>
    )
}
